import { CircularProgress, LinearProgress } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';



declare global {

    interface ModelViewerElement extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
      
      /**
       * The URL to the 3D model. Only glTF/GLB models are supported.
       */
      src?: string,
      
    
      /**
       * Configures the model with custom text that will be used to describe the model to viewers 
       * who use a screen reader or otherwise depend on additional semantic context to understand 
       * what they are viewing.
       */
      alt?: string,

      /**
       * Displays an image instead of the model, useful for showing the user something 
       * before a model is loaded and ready to render.
       */
      poster?: string,

      /**
       * An enumerable attribute describing under what conditions the model should be preloaded.
       * @default auto
       */
      loading?: "auto" | "lazy" | "eager"



      /** Staging & Cameras */
      "camera-controls"?: boolean;

      "auto-rotate"?: boolean;

      /**
       * @default 3000
       */
      "auto-rotate-delay"?: number;

      /** AUGMENTED REALITY */
      /**
       * Enable the ability to launch AR experiences on supported devices.
       */
      ar?: boolean,

      /**
       * 
       */
      "ios-src"?: string


      "shadow-intensity"?: number


      "skybox-image"?: string,

      "environment-image"?: "neutral" | "legacy" | string,

    }

    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': ModelViewerElement;
        }
    }
}

export const ModelViewer = (props: ModelViewerElement & {
  onQuickLookButtonTapped?: () => void,
  onProgress?: (progress: number) => void,
}) => {

  const {onQuickLookButtonTapped, onProgress, ...elementProps} = props;

  const elementRef = React.useRef<ModelViewerElement>(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const element = elementRef.current as Partial<HTMLElement>;
    if (element) {
      const onTap = (event: any) => {
        onQuickLookButtonTapped?.();
      }
      const progressHandler = (event: any) => {
        setLoadingProgress(event.detail.totalProgress);
        onProgress?.(event.detail.totalProgress);
        console.log(event);
      }

      const onLoaded = () => {
        setLoaded(true);
      }

      element.addEventListener?.("quick-look-button-tapped", onTap)
      element.addEventListener?.("progress", progressHandler)
      element.addEventListener?.("load", onLoaded)
      return (() => {
        element.removeEventListener?.("quick-look-button-tapped", onTap)
        element.removeEventListener?.("progress", progressHandler)
        element.removeEventListener?.("load", onLoaded)
      })

    }
  }, [elementRef.current]);
  return <>
    <model-viewer className="model-viewer" ref={elementRef as any} {...elementProps}
    >

      <LinearProgress className="progress-bar" slot="progress-bar" variant="determinate" value={loadingProgress*100}
        style={{
          position: "absolute", 
          top: 0, width: "100%", 
          opacity: loaded ? 0 : 1, transition: "0.5s all",
          pointerEvents: loaded ? "none" : "all",
          zIndex: 2,
        }}
      />
      <div slot="progress-bar" />
      <CircularProgress 
        size="1.5em"
        style={{
          position: "absolute", 
          left: "0.9em", bottom: "0.3em",
          opacity: loaded ? 0 : 1, transition: "0.5s all",
          pointerEvents: loaded ? "none" : "all",
          zIndex: 2,
        }}
      />
      <div slot="poster"/>
      <div style={{
        position: "absolute", 
        left: 0, right: 0, top: 0, bottom: 0, 
        width: "100%", height: "100%",
        backgroundImage: `url("${props.poster}")`,
        backgroundSize: "contain", 
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        opacity: loaded ? 0 : 1, transition: "0.5s all",
        pointerEvents: loaded ? "none" : "all",
        zIndex: 1,

      }}></div>
      {
        props.children
      }
    </model-viewer>
  </>
}