import { useEffect, useState } from "react"

export const InitialFade = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, [])

  return (
    <div
      style={{
        position: "fixed",
        width: "100%", height: "100%",
        backgroundColor: "white",
        zIndex: 99999,
        opacity: loaded ? 0 : 1, transition: "0.5s all",
        pointerEvents: loaded ? "none" : "all",
      }}
    />
  )
}