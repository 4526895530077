import { Box, Dialog, DialogContent, DialogTitle, ListItemIcon, ListItemText, MenuItem, MenuList, Snackbar } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWeixin } from "@fortawesome/free-brands-svg-icons";
import { ContentCopy, Email, Facebook, IosShare } from "@mui/icons-material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareIcon from '@mui/icons-material/Share';

export interface ShareDialogProps {
  open: boolean;
  onClose?: () => void;
  subject?: string;
  content?: string;
  url?: string;
}

export const ShareDialog = (props: ShareDialogProps) => {
  const {open, onClose, subject, content, url} = props;
  const [copied, setCopied] = useState(false);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [])

  const nativeShareData: ShareData = useMemo(() => {
    return {
      title: subject,
      text: content,
      url: url
    }
  }, [subject, content])

  const nativeSharable = useMemo(() => {
    if (!!navigator.share && !!navigator.canShare && navigator.canShare(nativeShareData)) {
      return true;
    } else {
      return false;
    }
  }, [nativeShareData]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: "0.6em" }}><ShareIcon /> Share this chair</DialogTitle>
      <DialogContent dividers style={{padding: 0}} >
        <Box px={1} pt={0.5} pb={1}>
          <MenuList>
            {
              nativeSharable && (
                <MenuItem onClick={() => {
                  navigator.share(nativeShareData)
                }}>
                  <ListItemIcon>
                    <IosShare />
                  </ListItemIcon>
                  <ListItemText>
                    Share via other apps
                  </ListItemText>
                </MenuItem>
              )
            }
            <Snackbar
              open={copied}
              autoHideDuration={6000}
              onClose={() => setCopied(false)}
              message="Copied to Clipboard"
            />
            <CopyToClipboard text={content!} onCopy={() => setCopied(true)}>
              <MenuItem>
                <ListItemIcon>
                  <ContentCopy />
                </ListItemIcon>
                <ListItemText>
                  Copy to Clipboard
                </ListItemText>
              </MenuItem>
            </CopyToClipboard>
            <MenuItem onClick={() => {
              window.open(`mailto:?subject=${subject}&body=${content}`);
            }}>
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <ListItemText>
                Email
              </ListItemText>
            </MenuItem>

            <MenuItem onClick={() => {
              window.open(`https://api.whatsapp.com/send?text=${content}`);
            }}>
              <ListItemIcon>
                <WhatsAppIcon />
              </ListItemIcon>
              <ListItemText>
                WhatsApp
              </ListItemText>
            </MenuItem>

            <MenuItem onClick={() => {
              window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
            }}>
              <ListItemIcon>
                <Facebook />
              </ListItemIcon>
              <ListItemText>
                Facebook
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Box>
      </DialogContent>
    </Dialog>
  )
}