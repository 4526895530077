import { Accordion, AppBar, Box, Button, Card, Container, CssBaseline, Divider, IconButton, Input, InputAdornment, Paper, TextField, Toolbar, Typography } from "@mui/material"
import querystring from "querystring";
import { ModelViewer } from "../components/ModelViewer";
import ChairIcon from '@mui/icons-material/Chair';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShareIcon from '@mui/icons-material/Share';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "../styles/products.scss";
import { InitialFade } from "../components/InitialFade";
import { useState } from "react";
import { ShareDialog } from "../components/ShareDialog";
export interface ProductProp {
  styleCode: string;
  title: string;
  subtitle?: string;
  src: string;
  iosSrc: string;
  price?: string;
  poster?: string;
  productLink?: string;
}

const products: ProductProp[] = [
  {
    styleCode: "25123456",
    title: "Carl Hansen - OW149 Colonial Chair / Walnut / Oil / Leather Thor 307",
    subtitle: "Ole Wanscher’s OW149 Colonial Chair combines contrasting forms and materials to create a refined and luxurious whole. This stylish chair is his most famous furniture work and a true pleasure to experience.",
    src: "/models/ow149/ow149.glb",
    iosSrc: "/models/ow149/ow149.usdz",
    price: "HK$38,000",
    poster: "/models/ow149/ow149.jpg",
    productLink: "https://www.2ndfloor.gr/shop/furniture/furniture-seating/lounge-chairs/ow149-colonial-chair-walnut-oil-leather-thor-307/",
  },
  {
    styleCode: "25123456",
    title: "Carl Hansen - OW149 Colonial Chair / Walnut / Oil / Leather Thor 307",
    subtitle: "Ole Wanscher’s OW149 Colonial Chair combines contrasting forms and materials to create a refined and luxurious whole. This stylish chair is his most famous furniture work and a true pleasure to experience.",
    src: "/models/ow149/ow149.glb",
    iosSrc: "/models/ow149/ow149.usdz",
    price: "HK$38,000",
    poster: "/models/ow149/ow149.jpg",
    productLink: "https://www.2ndfloor.gr/shop/furniture/furniture-seating/lounge-chairs/ow149-colonial-chair-walnut-oil-leather-thor-307/",
  },
  {
    styleCode: "25123456",
    title: "Carl Hansen - OW149 Colonial Chair / Walnut / Oil / Leather Thor 307",
    subtitle: "Ole Wanscher’s OW149 Colonial Chair combines contrasting forms and materials to create a refined and luxurious whole. This stylish chair is his most famous furniture work and a true pleasure to experience.",
    src: "/models/ow149/ow149.glb",
    iosSrc: "/models/ow149/ow149.usdz",
    price: "HK$38,000",
    poster: "/models/ow149/ow149.jpg",
    productLink: "https://www.2ndfloor.gr/shop/furniture/furniture-seating/lounge-chairs/ow149-colonial-chair-walnut-oil-leather-thor-307/",
  }
]



export const ProductItem = (props: {
  product: ProductProp
}) => {
  const {product} = props;
  const [expanded, setExpanded] = useState(false);
  const [shareDialogOpened, setShareDialogOpened] = useState(false);

  const quickLookQuery = querystring.stringify({
    callToAction: "Detail",
    checkoutTitle: product.title,
    // checkoutSubtitle: product.subtitle,
    checkoutSubtitle: "Chair",
    // price: product.price
  });
  const onBuyNow = () => {
    if (product.productLink) {
      window.location.href = product.productLink;
    }
  }
  return (
    <Card style={{overflow: "hidden", position: "relative", zIndex:3 }}>
      <ShareDialog
        open={shareDialogOpened}
        onClose={() => setShareDialogOpened(false)}
        subject={"Share Subject"}
        content={"I would like to share this content: https://test.com/product/12345"}
        url={"https://test.com/product/12345"}
      />
      <ModelViewer
        style={{width: "100%", height: "320px", overflow: "hidden"}}
        src={product.src}
        poster={product.poster}
        camera-controls
        auto-rotate
        auto-rotate-delay={0}
        ar
        ios-src={`${product.iosSrc}#${quickLookQuery}`}
        shadow-intensity={2}
        // environment-image="/home-bg.jpg"
        // skybox-image="/home-bg-3d.jpg"
        onQuickLookButtonTapped={() => {
          onBuyNow();
        }}
      >
        {/* <div slot="progress-bar" style={{width: "100%", height: "10px", position: "absolute"}}>
          </div> */}
        <Button 
          variant="outlined" 
          slot="ar-button" 
          startIcon={<ViewInArIcon className="shake" />} 
          style={{
            borderRadius: "2em",
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            bottom: 0, right: "1em", textTransform: "none",
            zIndex: 2,
          }}
        >
          View in AR
        </Button>
      </ModelViewer>
      <Box px={3} pb={2}>
        <Typography variant="h6" gutterBottom mt={1} lineHeight={1.3}>
          <center>{product.title}</center>
        </Typography>
        <Typography variant="subtitle2" gutterBottom fontWeight={"normal"}>
          <center><small>STYLE {product.styleCode}</small></center>
        </Typography>
        <Divider />
        <Typography variant="body2" mt={2}>
          {product.subtitle}
        </Typography>
        <Box py={0.5} />
        <Box sx={{display: "flex", alignItems: "center", }}>
          {/* <Typography>{product.price}</Typography> */}
          <IconButton sx={{marginLeft: "auto", marginRight: "0.3em"}} 
            onClick={() => setShareDialogOpened(true)}
          ><ShareIcon/></IconButton>
          <Button variant="contained" startIcon={<StorefrontIcon />}
            onClick={() => {
              onBuyNow();
            }}
          >Buy Now</Button>
          
        </Box>
      {/* <model-viewer src="../../shared-assets/models/Astronaut.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="A 3D model of an astronaut" skybox-image="../../shared-assets/environments/aircraft_workshop_01_1k.hdr" ios-src="../../shared-assets/models/Astronaut.usdz" xr-environment></model-viewer> */}
      </Box>
      {/* <Accordion
        expanded={expanded}
        onChange={() => setExpanded(flag => !flag)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            General settings
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </Card>
  )

}

export const ProductsPage = () => {
  return <>
    <Box sx={{ display: 'flex' }}>
      <InitialFade />
      <CssBaseline />
      <AppBar>
        <h2 style={{display: "flex", alignItems: "center", justifyContent: "center"}}><ChairIcon fontSize="large" style={{marginRight: "0.4em"}}/> LOGO</h2>

      </AppBar>
      <Box component="main" sx={{ pt: 9, pb: 12 }} width="100%" style={{background: "#f6f6f6"}}>
        {/* <Toolbar /> */}
        <Container maxWidth="sm" style={{ display: "flex", flexDirection: "column"}}>
          <form action="" onSubmit={(event) => {
            event.preventDefault();
          }}>
            <Box sx={{display: "flex"}} px={0.5} py={2}>
            
              <Input size="small" sx={{width: "100%"}}
                type="search"
                placeholder="Search Products"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <Button startIcon={<FilterListIcon />} style={{textTransform: "none"}}>
                    Filter
                  </Button>
                }
              />
            </Box>
          </form>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em"}}>
            {
              products.map((product, index: number) => {
                return <ProductItem key={index} product={product} />
              })
            }
          </div>
        </Container>
      </Box>
    </Box>


    <AppBar position="static">
      <Box px={2} py={3}>
        <Container maxWidth="sm">
          <Box width="100%" px={5} sx={{display: "flex", gap: "0.3em 1em", fontSize: "0.7em", alignItems: "center", justifyContent: "center", flexWrap: "wrap",}}>
            <div style={{whiteSpace: "nowrap"}}>Terms of Service</div>
            <div style={{whiteSpace: "nowrap"}}>Privacy Consent</div>
            <div style={{whiteSpace: "nowrap"}}>© 2023 ABC Company Ltd</div>
          </Box>
        </Container>
      </Box>
    </AppBar>
  </>
}